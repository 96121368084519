import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "git repo",
  url: "https://github.com/ArohanD/morse-code-translator"
}, {
  text: "live demo",
  url: "https://morse-code-translator.vercel.app/"
}];
export const tags = ['React', 'Node', 'Javascript', 'SpeechSynthesis', 'AWS', 'EC2', 'PM2', 'Express', 'ARIA', 'Javascript', 'Material UI'];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='image-right-wrapper'>
      <div className='image-right-text'>
        <h1>{`Vocal Codes`}</h1>
        <p>{`An app that allows immobile persons to communicate with morse code using a single input button. This app is Inspired by my uncle, who is now immobile due to an injury. Its features are as follows:`}</p>
        <ul>
          <li parentName="ul">{`Users are able to have input with short and long presses, either as yes/no or in morse code, and have their input translated and spoken to others.`}</li>
          <li parentName="ul">{`A predictive text engine allows for easier sentence construction.`}</li>
          <li parentName="ul">{`The app has adjustable sensitivities so they can be tailored to users with different needs.`}</li>
          <li parentName="ul">{`A high-contrast color scheme is utilized for facilitated reading.`}</li>
        </ul>
        <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
        <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      </div>
      <img className="image-right-image" src="https://images.squarespace-cdn.com/content/v1/54c0768de4b07740895fb25f/1572025068065-RA1WPF36FKTPOIGX2ZJM/ke17ZwdGBToddI8pDm48kJO_Aj-M1eF3QqAViXELqo97gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmQx21nqIT8mD2igu2ZXJ_19t5gqJ5CkHK8N9f0mJ1zcy2uhiDiJWxyOnCWyLf2OmL/ec2-54-173-143-128.compute-1.amazonaws.com_Translator%28iPhone+6_7_8+Plus%29+%281%29.png?format=750w" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      